import React, { FC } from 'react';
import './OpenBlog.css';

interface OpenBlogProps { }

const OpenBlog: FC<OpenBlogProps> = () => (
  <div className="OpenBlog" data-testid="OpenBlog">
    <a type="button" className="btn open-blog-btn open-blog-btn-circle" href="https://blog.zfir.dev" target="_blank">
      <i className="fa-solid fa-blog"></i>
    </a>
  </div>
);

export default OpenBlog;

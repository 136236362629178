import badge1 from '../../assets/badges/AI_Analyst_-_Exporer_2019.png';
import badge2 from '../../assets/badges/AI_Analyst_-_Mastery_2019.png';
import badge3 from '../../assets/badges/apm-student.png';
import badge4 from '../../assets/badges/Badge-Designer.png';
import badge5 from '../../assets/badges/Badge-SecArch.png';
import badge6 from '../../assets/badges/cloud-core.png';
import badge7 from '../../assets/badges/cybersecurity-essentials.png';
import badge8 from '../../assets/badges/data-analysis-using-python.png';
import badge9 from '../../assets/badges/introduction-to-cybersecurity.png';
import badge10 from '../../assets/badges/introduction-to-iot.png';
import badge11 from '../../assets/badges/machine-learning-with-python-level-1.png';
import badge12 from '../../assets/badges/MTA-Software-Development-Fundamentals-2018.png';
import badge13 from '../../assets/badges/python-for-data-science.png';
import badge14 from '../../assets/badges/rasa_certified_developer_badge.png';

const badges = [
    badge1,
    badge2,
    badge3,
    badge4,
    badge5,
    badge6,
    badge7,
    badge8,
    badge9,
    badge10,
    badge11,
    badge12,
    badge13,
    badge14
];

export default badges;

import React, { FC } from 'react';
import './BadgeCard.css';

interface BadgeCardProps {
  imagePath: string;
}

const BadgeCard: FC<BadgeCardProps> = (props) => (
  <div className="d-flex flex-column BadgeCard" data-testid="BadgeCard">
    <div className="card BadgeCardWrapper">
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <img className="BadgeCardImage" src={props.imagePath} alt={props.imagePath} />
      </div>
    </div>
  </div>
);

export default BadgeCard;

import React, { FC } from 'react';
import Heading from '../Heading/Heading';
import BadgeCard from '../BadgeCard/BadgeCard';
import badges from './import-badges';
import './Badges.css';

interface BadgesProps {}

const Badges: FC<BadgesProps> = () => (
  <div className="Badges" data-testid="Badges">
    <div className="container">
      <Heading title="Badges" />
      <div className="container">
        <div className="row">
          { 
            badges.map((badge: any, index: any) => {
              return ( 
                <div className="col-md-4">
                  <BadgeCard imagePath={badge}/>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  </div>
);

export default Badges;

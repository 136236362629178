import React, { FC } from 'react';
import './Footer.css';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className="Footer" data-testid="Footer">
    <footer className="text-center" style={{background: "#10ddc2"}}>
        <div className="container text-center py-4 py-lg-5">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col" style={{padding: "10px"}}>
              <p className="my-0" style={{color: "#053b50"}}>
                Copyright © {new Date().getFullYear()}. &nbsp;
                <a className="FooterText" href="#">Zafir.</a>
              </p>
            </div>
            <div className="col" style={{padding: "10px"}}>
              <p className="my-0" style={{color: "#053b50"}}>
                Developed using React <i className="fab fa-react fa-2xl" style={{color: "#053b50"}}></i>
              </p>
            </div>
          </div>
        </div>
    </footer>
  </div>
);

export default Footer;

import React, { FC } from 'react';
import './ProjectCard.css';

interface ProjectCardProps {
  imagePath?: string
  title: string;
  description: string;
  appUrl?: string;
  websiteUrl?: string;
  gitUrl?: string;
}

const ProjectCard: FC<ProjectCardProps> = (props) => (
  <div className="d-flex flex-column ProjectCard" data-testid="ProjectCard">
    <div className="card ProjectCardWrapper">
        <div className="card-body d-flex flex-column justify-content-center align-items-center">
            {props.imagePath && <img className="ProjectCardImage" src={props.imagePath} />}
            <h4 className="card-title" style={{color: "#053b50"}}>{props.title}</h4>
            <p className="card-text" style={{color: "#053b50"}}>{props.description}</p>
            <div>
              {props.appUrl && <a className="btn btn-primary ProjectCardButton" type="button" href={props.appUrl}>App</a>}
              {props.websiteUrl && <a className="btn btn-primary ProjectCardButton" type="button" href={props.websiteUrl}>Website</a>}
              {props.gitUrl && <a className="btn btn-primary ProjectCardButton" type="button" href={props.gitUrl}>Git</a>}
            </div>
        </div>
    </div>
  </div>
);

export default ProjectCard;

import React, { FC } from 'react';
import './CVDownload.css';

interface CVDownloadProps {}

const CVDownload: FC<CVDownloadProps> = () => (
  <div className="CVDownload" data-testid="CVDownload">
    <a type="button" className="btn cv-btn cv-btn-circle" href="cv.pdf" download="cv" target="_blank">
      <i className="fa fa-arrow-down"></i>
      <span>CV</span>
    </a>
  </div>
);

export default CVDownload;

import React, { FC } from 'react';
import './Heading.css';

interface HeadingProps {
  title: string;
}

const Heading: FC<HeadingProps> = (props) => (
  <div className="Heading" data-testid="Heading">
    <h2 className="Heading-Text">
      <strong>_</strong>
      {props.title.toLowerCase()}
    </h2>
  </div>
);

export default Heading;

import React, { FC } from 'react';
import './NavbarLinksList.css';

interface NavbarLinksListProps {}

const NavbarLinksList: FC<NavbarLinksListProps> = () => (
  <div className="NavbarLinksList" data-testid="NavbarLinksList">
    <div id="navcol-2" className="collapse navbar-collapse">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item" style={{color: "#053b50"}}><a className="nav-link" href="mailto:zafir@zfir.dev" style={{color: "#053b50"}} target="_blank"><i className="material-icons" style={{color: "#053b50"}}>mail</i></a></li>
        <li className="nav-item" style={{color: "#053b50"}}><a className="nav-link" href="https://www.linkedin.com/in/zfir/" style={{color: "#053b50"}} target="_blank"><i className="fab fa-linkedin-in" style={{color: "#053b50"}}></i></a></li>
        <li className="nav-item" style={{color: "#053b50"}}><a className="nav-link" href="https://github.com/zfir/" style={{color: "#053b50"}} target="_blank"><i className="fab fa-github" style={{color: "#053b50"}}></i></a></li>
        <li className="nav-item" style={{color: "#053b50"}}><a className="nav-link" href="https://zfir.medium.com/" style={{color: "#053b50"}} target="_blank"><i className="fab fa-medium" style={{color: "#053b50"}}></i></a></li>
        <li className="nav-item" style={{color: "#053b50"}}><a className="nav-link" href="https://twitter.com/zafirsk_" style={{color: "#053b50"}} target="_blank"><i className="fab fa-twitter" style={{color: "#053b50"}}></i></a></li>
      </ul>
    </div>
  </div>
);

export default NavbarLinksList;

import React, { FC } from 'react';
import Heading from '../Heading/Heading';
import ProjectCard from '../ProjectCard/ProjectCard';
import dartdorarLogo from '../../assets/logos/dardotar-logo.png';
import budgeterLogo from '../../assets/logos/budgeter-logo.png';
import mwfLogo from '../../assets/logos/mwf-logo.png';
import nohungerLogo from '../../assets/logos/nohunger-logo.png';
import crashlensLogo from '../../assets/logos/crashlens-logo-white.png';
import mangroveMattersLogo from '../../assets/logos/mangrove-matters-logo.png';
import './Projects.css';

interface ProjectsProps {}

const Projects: FC<ProjectsProps> = () => (
  <div className="Projects" data-testid="Projects">
    <div className="container">
        <Heading title="Projects" />
        <div className="container">
          <div className="row">
              <div className="col-md-4">
                <ProjectCard imagePath={dartdorarLogo} title="DARTdotAR" description="Developed using C#, Unity and Adobe XD" appUrl="https://play.google.com/store/apps/details?id=com.zafir.dartdotar&hl=en&gl=US" />
              </div>
              <div className="col-md-4">
                <ProjectCard imagePath={budgeterLogo} title="Budgeter" description="Developed using Android Studio and Java" appUrl="https://play.google.com/store/apps/details?id=com.silverappstudios.budgeting" />
              </div>
              <div className="col-md-4">
                <ProjectCard imagePath={mwfLogo} title="MWF Game" description="Developed usign Unity and Adobe XD" />
              </div>
          </div>
          <div className="row">
              <div className="col-md-4">
                <ProjectCard imagePath={nohungerLogo} title="NoHunger" description="Developed using Dart, Flutter and Google Firebase" />
              </div>
              <div className="col-md-4">
                <ProjectCard imagePath={crashlensLogo} title="CrashLens" description="Developed using Dart, Flutter, Python and FastAPI" appUrl="https://play.google.com/store/apps/details?id=com.zafir.crashlens&hl=en&gl=US" websiteUrl="https://crashlens.zfir.dev/" />
              </div>
              <div className="col-md-4">
                <ProjectCard imagePath={mangroveMattersLogo} title="Mangrove Matters" description="Developed using Ionic, Cordova and Ruby on Rails" appUrl="https://play.google.com/store/apps/details?id=com.reef.mangrove&hl=en&gl=US" />
              </div>
          </div>
          <div className="row">
              <div className="col-md-4">
                <ProjectCard title="RSS Medium Blog Viewer" description="Developed using EmberJS and Bootstrap" websiteUrl="https://blog.zfir.dev" gitUrl="https://github.com/zfir-dev/rss-medium-blog-viewer" />
              </div>
              <div className="col-md-4">
                <ProjectCard title="Blog Post Template Exporter" description="Developed using Pure JavaScript" websiteUrl="https://blog-post-template-exporter.sc.zfir.dev/" />
              </div>
              <div className="col-md-4">
                <ProjectCard title="Link Shortener" description="Developed using Flask, HTML, JavaScript and PostgreSQL" websiteUrl="https://l.zfir.dev/D2Xzywy" />
              </div>
          </div>
          <div className="row">
              <div className="col-md-4">
                <ProjectCard title="QuickQR" description="Developing using TypeScript and Chrome Extension APIs (IN PROGRESS)" websiteUrl="https://chromewebstore.google.com/detail/jcccpgopmdbmlihmmepncacidmmnkaaj/" />
              </div>
          </div>
      </div>
    </div>
  </div>
);

export default Projects;

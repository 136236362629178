import React, { FC } from 'react';
import './ExperienceCard.css';
import datetimeDifference from 'datetime-difference';

interface ExperienceCardProps {
  startDuration: string;
  endDuration: string;
  title: string;
  company: string;
}

const ExperienceCard: FC<ExperienceCardProps> = (props) => {
  let duration;
  if (props.endDuration === "Present") {
    duration = datetimeDifference(new Date("1" + props.startDuration), new Date(Date.now()));
  } else {
    duration = datetimeDifference(new Date("1" + props.startDuration), new Date("31" + props.endDuration));
  }
  return (
    <div className="d-flex flex-column ExperienceCard" data-testid="ExperienceCard">
      <div className="card ExperienceCardWrapper">
        <div className="card-body d-flex flex-column justify-content-center align-items-center">
          <p className="card-text ExperienceCardText">{props.company}<br /></p>
          <p className="card-text ExperienceCardText"><strong>{props.title}</strong></p>
          <p className="card-text ExperienceCardText"><em>{props.startDuration} - {props.endDuration}</em></p>
          <p className="card-text ExperienceCardText">
            <em>
              {duration.years ?
                <span>{duration.years} year
                  {duration.years > 1 ?
                    <span>s</span>
                    :
                    null}
                </span>
                :
                null}
              {duration.months || duration.years === 1 ?
                <span> {duration.months + 1} month
                  {duration.months + 1 > 1 ?
                    <span>s</span>
                    :
                    null}
                </span>
                :
                null}
            </em>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;

import React, { FC } from 'react';
import Heading from '../Heading/Heading';
import ExperienceCard from '../ExperienceCard/ExperienceCard';
import './Experiences.css';

interface ExperiencesProps {}

const Experiences: FC<ExperiencesProps> = () => (
  <div className="Experiences" data-testid="Experiences">
    <div className="container">
      <Heading title="Experiences" />
      <div className="container">
          <div className="row">
              <div className="col-md-4">
                <ExperienceCard startDuration="June 2020" endDuration="September 2020" title="Web Engineer Intern" company="Nova Interaction LTD" />
              </div>
              <div className="col-md-4">
                <ExperienceCard startDuration="August 2021" endDuration="Present" title="Consultant" company="Spoon Consulting LTD" />
              </div>
              <div className="col-md-4">
                <ExperienceCard startDuration="August 2021" endDuration="Present" title="Software Engineer" company="SharinPix" />
              </div>
          </div>
      </div>
    </div>
  </div>
);

export default Experiences;

import React, { FC, useEffect, useState } from 'react';
import Heading from '../Heading/Heading';
import './BlogPosts.css';
import { cp } from 'fs';

interface BlogPostsProps { }

const BlogPosts: FC<BlogPostsProps> = () => {
   let [blogPosts, setBlogPosts] = useState<any>(null);

   useEffect(() => {
      const fetchBlogPosts = async () => {
         try {
            const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@zfir');
            const data = await response.json();
            console.log(data);
            setBlogPosts(data);
         } catch (error) {
            console.error('Error fetching blog posts:', error);
         }
      };

      fetchBlogPosts();
   }, []);

   const openBlogPost = (link: string) => {
      window.open(link, "_blank");
   }

   return (
      <div className="BlogPosts" data-testid="BlogPosts">
         <div className="container">
            <Heading title="Blog/Article Posts" />
            <div className="row">
               {blogPosts &&
                  blogPosts.items &&
                  blogPosts.items.map((post: any, index: number) => {
                     const date = new Date(post.pubDate);
                     return (
                        <div className="col-md-4" key={index}>
                           <div className="card BlogPostCardWrapper">
                              <div
                                 className="card-body"
                                 onClick={() => openBlogPost(post.link)}
                              >
                                 {post.thumbnail && post.thumbnail.includes("cdn") && (
                                    <img className="BlogPostCardImage" src={post.thumbnail} />
                                 )}
                                 <p className="card-title BlogPostCardText">
                                    <strong>{post.title}</strong>
                                 </p>
                                 {post.categories && post.categories != "" && (
                                    <p className="card-text BlogPostCardText">
                                       ({post.categories.join(', ')})
                                    </p>
                                 )}
                                 <p className="card-text BlogPostCardText">
                                    <i>
                                       {date.toDateString()}
                                    </i>
                                 </p>
                              </div>
                           </div>
                        </div>
                     );
                  })}
            </div>
         </div>
      </div>
   );
};


export default BlogPosts;

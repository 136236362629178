import React, { FC } from 'react';
import NavbarLinksList from '../NavbarLinksList/NavbarLinksList';
import './Navbar.css';
import logo from '../../assets/logos/logo.png';

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => (
  <nav className="navbar navbar-light navbar-expand-md py-3" style={{background: "#10ddc2"}}>
    <div className="container">
      <img src={logo} alt={logo} style={{width: "100px"}} />
      <div className="d-md-none d-lg-block">
        <NavbarLinksList />
      </div>
    </div>
  </nav>
);

export default Navbar;

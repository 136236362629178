import React, { FC } from 'react';
import NavbarLinksList from '../NavbarLinksList/NavbarLinksList';
import './MobileNavbar.css';

interface MobileNavbarProps {}

const MobileNavbar: FC<MobileNavbarProps> = () => (
  <div className="MobileNavbar d-md-block d-lg-none" data-testid="MobileNavbar">
    <div className="card MobileNavbarWrapper">
      <div className="card-body">
        <NavbarLinksList />
      </div>
    </div>
  </div>
);

export default MobileNavbar;
